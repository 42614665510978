/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Title, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Text, Image } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Reference"}>
        <ColumnCover name={"[[UNIsecname10]]"}>
          
          <Column className="--menu --style4 pb--30 pt--30" menu={true}>
            
            <Menu className="--full pb--06 pt--06" style={{"maxWidth":1540}} fullscreen={true}>
              
              <ColumnWrapper className="menu-logo-box">
                
                <Title className="title-box fs--24 w--600 title-box--invert swpf--uppercase" content={"<span style=\"color: var(--color-variable-1);\">[[UNIname4]]</span>"}>
                </Title>

                <Subtitle className="subtitle-box fs--20 subtitle-box--invert mt--02" content={"[[FITtitle2]]"}>
                </Subtitle>

              </ColumnWrapper>

              <MenuWrap >
                
                <MenuButton >
                </MenuButton>

                <MenuWrapBox >
                  
                  <MenuButtonClose >
                  </MenuButtonClose>

                  <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 w--600 swpf--uppercase lh--16 pl--08 pr--08" innerClassName="pl--0 pr--0" href={"/[[UNIsectionname10]]"} target={""} content={"[[UNIsection10]]"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 w--600 swpf--uppercase lh--16 pl--08 pr--08" innerClassName="pl--0 pr--0" href={"/[[UNIsectionname11]]"} target={""} content={"[[UNIsection11]]"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 w--600 swpf--uppercase lh--16 pl--08 pr--08" innerClassName="pl--0 pr--0" href={"/[[UNIsectionname3]]"} target={""} content={"[[UNIsection3]]"}>
                  </Button>

                </MenuWrapBox>

              </MenuWrap>

            </Menu>

          </Column>

        </ColumnCover>


        <Column className="--style3 bg--center --full" name={"[[UNIsecname1]]"} style={{"backgroundColor":"rgba(11, 13, 19, 1)"}} fullscreen={true}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --center el--1 flex--center" anim={"2"} animS={"4"} style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper className="--left pb--02 pl--02 pr--02 pt--02" anim={null} animS={null} style={{"maxWidth":1200}}>
              
              <Title className="title-box title-box--shadow5 fs--102 title-box--invert" content={"[[UNIsection11]]"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--invert" content={"<span style=\"color: var(--color-variable-1);\">[[FITphrase3]]</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":900}} content={"[[UNITextBigger]]"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"[[UNIsectionname11]]"}>
          
          <ColumnWrap className="column__flex --left el--2 pb--20 pl--25 pr--25 pt--20" style={{"maxWidth":1280}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box fs--43" content={"[[FITreference1]]"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left" content={"[[UNITextBigger]]"}>
              </Text>

              <Subtitle className="subtitle-box" content={"— [[UNIname15]]"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--2 mt--30 pb--20 pl--25 pr--25 pt--20" style={{"maxWidth":1280}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box fs--43" content={"[[FITreference1]]"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left" content={"[[UNITextBigger]]"}>
              </Text>

              <Subtitle className="subtitle-box" content={"— [[UNIname15]]"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--1 mt--30 pb--20 pl--25 pr--25 pt--20" style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Image alt={"[[ADMINPicAltText]]"} src={"https://cdn.swbpg.com/t/i/template/[[id_theme]]/img-1_s=350x_.jpg"} svg={false} ratio={"16:9"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/[[id_theme]]/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/[[id_theme]]/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/[[id_theme]]/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/[[id_theme]]/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/[[id_theme]]/img-1_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--2 mt--30 pb--30 pl--25 pr--25 pt--20" style={{"maxWidth":1280}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box fs--43" content={"[[FITreference1]]"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left" content={"[[UNITextBigger]]"}>
              </Text>

              <Subtitle className="subtitle-box" content={"— [[UNIname15]]"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1xaf0uf --style3 bg--center --full" name={"[[UNIsecname1]]"} style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/i/template/[[id_theme]]/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/i/template/[[id_theme]]/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/i/template/[[id_theme]]/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/i/template/[[id_theme]]/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/i/template/[[id_theme]]/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/i/template/[[id_theme]]/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/i/template/[[id_theme]]/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/i/template/[[id_theme]]/fullscreen_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s4" anim={"7"} animS={"4"}>
              
              <Title className="title-box fs--72 title-box--invert" content={"[[FITphrase1]]"}>
              </Title>

              <Subtitle className="subtitle-box fs--30 subtitle-box--invert mt--20" content={"[[FITphrase2]]"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--filling2 fs--20 swpf--uppercase mt--30" href={"/[[UNIsectionname3]]"} content={"[[UNIletsmeet]]"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"[[UNIsecname7]]"} style={{"backgroundColor":"rgba(12, 14, 20, 1)"}}>
          
          <ColumnWrap className="column__flex el--3 pb--16 pt--10" style={{"maxWidth":1200}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--24 w--600 title-box--invert swpf--uppercase" content={"<span style=\"color: rgb(255, 212, 9);\">[[UNIname4]]</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--20 subtitle-box--invert mt--02" content={"[[FITtitle2]]"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" content={"[[UNITextBigger]]"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/"} content={"[[UNIsection1]]"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/[[UNIsectionname10]]"} content={"[[UNIsection10]]"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/[[UNIsectionname11]]"} content={"[[UNIsection11]]"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/[[UNIsectionname3]]"} content={"[[UNIsection3]]"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}